<template>
    <v-main>
      <div class="menu2" id="lotereya">
        <Header2/>

          <!-- ------------------------------------------------------------ -->

        <v-container class="position-relative">
          <h1 class="menu1_head1 mb-5">{{ $t('profile') }}</h1>
          <br>
          <br>

          <v-simple-table dark class="tess-tbl">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('name_surname') }}
                  </th>
                  <th class="text-left">
                   {{ $t('msisdn') }}
                  </th>
                  <th class="text-left">
                   {{ $t('email') }}
                  </th>
                  <th class="text-left">
                   {{ $t('total_points') }}
                  </th>
                  <th class="text-left">
                   {{ $t('pending_points') }}
                  </th>
                  <th class="text-left">
                   {{ $t('spending_points') }}
                  </th>
                  <th class="text-left">
                   {{ $t('remaining_points') }}
                  </th>
                  <th class="text-right">
                    <v-btn
                    to="changepassword"
                    class="buttonh text-none"
                  >
                      {{ $t('change_password') }}               

                  </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr  class="no-hover">
                  <td>{{ user.name+' '+user.surname }}</td>
                  <td>{{ user.msisdn }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.total }}</td>
                  <td>{{ user.pending }}</td>
                  <td>{{ user.spend }}</td>
                  <td>{{ user.remaining }}</td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row>
            <v-col
            cols="6"
            md="6"
            sm="12"
            >
              <v-simple-table dark class="tess-tbl">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t('instagram_username_label') }}
                        <v-btn
                          v-if="!user.instagram"
                          class="buttonh text-none"
                          @click.prevent="saveUser"
                        >
                            {{ $t('save') }}               

                        </v-btn>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="no-hover">
                      <td>
                        <v-text-field
                        class="mb-3 form_field" 
                        :label="$t('instagram_username')"
                        v-model="instagram"
                        type="text"
                        outlined
                        color="#11B800"
                        :disabled="user.instagram? true:false"
                      ></v-text-field>
                      </td>
                    </tr>
                    <tr class="no-hover"><td>{{ $t('instagram_text') }}</td></tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col
            cols="6"
            md="6"
            sm="12"
            >
            <v-simple-table dark class="tess-tbl">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        {{ $t('address_label') }}
                        <v-btn
                          class="buttonh text-none"
                          @click.prevent="saveUser"
                        >
                            {{ $t('save') }}               

                        </v-btn>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr class="no-hover">
                      <td>
                        <v-text-field
                        class="mb-3 form_field" 
                        :label="$t('address')"
                        v-model="address"
                        type="text"
                        outlined
                        color="#11B800"
                      ></v-text-field>
                      </td>
                    </tr>
                    <tr class="no-hover"><td>{{ $t('address_text') }}</td></tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
            <!-- Instagram ad -->
            <div class="igAdContainer position-absolute" @click="toInstagram">
              <img :src="$t('instagram_ad')" alt="Instagram ad" class="instagram_ad">
            </div>
          <div class="menus" style="min-height:600px">
            <v-row>
              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center mt-sm-9"
              >
              <h2 class="mb-5 green-text2">{{ $t('qebz_time') }}</h2>
                <v-simple-table dark class="tess-tbl">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          №
                        </th>
                        <th class="text-center">
                          {{ $t('check_upload_time') }}
                        </th>
                        <th class="text-center">
                          {{ $t('status') }}
                        </th>
                        <th class="text-center">
                          {{ $t('points') }}
                        </th>
                        <th class="text-center">
                          {{ $t('show_check') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,i) in items"
                        :key="item.id"
                      >
                        <td>{{ i+1 }}</td>
                        <td>{{ date_format(item.createdAt) }}</td>
                        <td v-if="item.status == 0">{{ $t('approved') }}</td>
                        <td v-else-if="item.status == 1">{{ $t('pending') }}</td>
                        <td v-else>{{ $t('rejected') }}</td>
                        <td>{{ item.points }}</td>
                        <td>
                          <v-btn
                            class="button"
                            :href="'https://monitoring.e-kassa.gov.az/#/index?doc='+item.fiscalId"
                            target="_blank"
                            >
                              <v-icon>{{icons.mdiEye}}</v-icon>
                            </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>

              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center mt-sm-9"
              >
              <h2 class="mb-5 green-text2">{{ $t('present') }}</h2>
                <v-simple-table dark class="tess-tbl">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          №
                        </th>
                        <th class="text-center">
                          {{ $t('present') }}
                        </th>
                        <th class="text-center">
                          {{ $t('status') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item,i) in user.presents"
                        :key="item.presentUserId"
                      >
                        <td>{{ i+1 }}</td>
                        <td>{{ item.presentName }}</td>
                        <td v-if="item.isDelivered">{{ $t('reached') }}</td>
                        <td v-else>{{ $t('not_reached') }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

              </v-col>
            </v-row>
          </div>
        </v-container>


          <!-- ----------------------------------------------------------------- -->
          <div>
            <v-row class="pt-3">
              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center my-sm-9"
              >
              <p class="t4"></p>  
            </v-col>
            </v-row>
          </div>
      </div>

    </v-main>
</template>
<script>
  import {
    mdiEye,
  } from '@mdi/js'
import axios from "axios";
import moment from "moment"
import userr from "../../store/user"
import store from "../../store/index"
import Header2 from "../parts/Header2.vue";
import i18n from "@/plugins/i18n"
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Profil',
    data: () => ({
      icons: {
            mdiEye,
        },
      user:{},
      instagram:'',
      address: '',
      items: [],
      config: {
            headers: {
                Authorization: ""
            }
        },
    }),
    methods: {
      date_format(k){
        return k ? moment(k).format('DD.MM.Y HH:mm:ss') : '';
      },
      toInstagram() {
        axios.put(store.getters.getUrl + "Property/IncrementInstaCount");
        window.location.href = 'https://www.instagram.com/tesstea.az?igsh=MXZkMG92azRjcXhvdw=='
    },
      saveUser(){
        axios.put(store.getters.getUrl + "user/userinfo", { address: this.address, instagram:this.instagram }, this.config)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(i18n.t("success"));
            this.user.instagram = this.instagram
            this.user.address = this.address

          }
          else {
            this.$toast.error(i18n.t("error"));

          }
        })
      }
    },
    created(){
      this.config.headers.Authorization = "Bearer " + userr.getters.getToken;
      var lang = localStorage.getItem('lang') || 'az'

      axios.get(store.getters.getUrl+'user/userinfo?lang='+lang,this.config)
          .then(response => {
            if(response.data.success){
              this.user = response.data.value
              this.address = this.user.address
              this.instagram = this.user.instagram
            }
            else {
              this.$toast.error('Xəta var!')
              this.loading = false
            }
          })
      axios.get(store.getters.getUrl+'receipt/AllByUser',this.config)
          .then(response => {
            if(response.data.success){
              this.items = response.data.value
            }
            else {
              this.$toast.error('Xəta var!')
              this.loading = false
            }
          })
    },

    components: { Header2 }
}
</script>
<style>
@import url('../../assets/css/custom.css');

.button {
  box-shadow: none!important;
  color: #fff!important;
}
.no-hover:hover{
  background-color: transparent!important;
}
</style>